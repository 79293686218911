@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: Arial, 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans',
    sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

main {
  z-index: 30;
}

dialog::backdrop {
  background-color: transparent;
  display: none !important;
}

dialog {
  padding: 0 !important;
  margin: 0 !important;
  position: fixed;
  bottom: 0;
}

html::-webkit-scrollbar {
  width: 12px;
}

html::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #95a5a6;
  border: 1px solid #cacaca;
}

html::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #2c3e50;
}

.toggled .line1 {
  transform: rotate(45deg) translateY(0.5rem);
}

.toggled .line2 {
  opacity: 0;
}

.toggled .line3 {
  transform: rotate(-45deg) translateY(-0.5rem);
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
